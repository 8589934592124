import React, { useState, useEffect, useRef } from 'react';
import gameData from './data.json'; // Adjust the path as needed
import { Helmet } from 'react-helmet';

const START_DATE = new Date('2024-10-08'); // First game date (October 8, 2024)

// Helper function to get date without time
const getDateWithoutTime = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

const WordPuzzleGame = () => {
  const [currentGame, setCurrentGame] = useState(null);
  const [guessedPhrase, setGuessedPhrase] = useState([]);
  const [revealedLetters, setRevealedLetters] = useState([]);
  const [revealCount, setRevealCount] = useState(0);
  const [gameWon, setGameWon] = useState(false);
  const [isFieldSelected, setIsFieldSelected] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  const [archiveDates, setArchiveDates] = useState([]); // For archive dates
  const [wordRevealUsed, setWordRevealUsed] = useState(false);
  const inputRefs = useRef([]);
  
  const rankEmojis = ['🦄', '🎠', '🏇', '🐎', '🦓', '🐴']; // 0 to 5 reveals

  const gameId = new URLSearchParams(window.location.search).get('gameId'); // Extract gameId from URL params

  useEffect(() => {
    if (gameData && gameData.length > 0) {
      generateArchiveDates();
      if (gameId) {
        loadGameById(gameId);
      } else {
        loadRandomPastGame();
      }
    }
  }, [gameData, gameId]);


  const handleRevealWord = () => {
    if (!currentGame || gameWon || wordRevealUsed) return;

    // Get array of word indices
    const words = currentGame.solution.text.split(' ');
    
    // Pick a random word index
    const randomWordIndex = Math.floor(Math.random() * words.length);
    const wordToReveal = words[randomWordIndex];

    // Update guessed phrase and revealed letters
    const updatedGuessedPhrase = [...guessedPhrase];
    let newRevealedLetters = [...revealedLetters];

    // Reveal the entire word
    wordToReveal.split('').forEach((char, charIndex) => {
      if (/[a-zA-Z]/.test(char)) {
        updatedGuessedPhrase[randomWordIndex][charIndex] = {
          char: char.toUpperCase(),
          type: 'alphabetic'
        };
        newRevealedLetters.push({ wordIndex: randomWordIndex, charIndex });
      }
    });

    // Update state
    setGuessedPhrase(updatedGuessedPhrase);
    setRevealedLetters(newRevealedLetters);
    setWordRevealUsed(true);
    setRevealCount(revealCount + 1); // Counts as one reveal
    checkWinCondition(updatedGuessedPhrase);
  };


  const generateArchiveDates = () => {
    const today = getDateWithoutTime(new Date());
    let dateArray = [];
    // Start with yesterday's date instead of today
    let currentDate = new Date(today);
    currentDate.setDate(currentDate.getDate() - 1);
    
    while (currentDate >= START_DATE) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() - 1);
    }
    setArchiveDates(dateArray);
  };


  // Seeded random number generator
const seededRandom = (seed) => {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
};

const loadRandomPastGame = () => {
  const today = getDateWithoutTime(new Date());
  
  // Filter games that have a print date earlier than today
  const validGames = gameData.filter(game => {
    const gameDate = getDateWithoutTime(new Date(game.printDate));
    return gameDate < today;
  });

  if (validGames.length === 0) {
    alert('No past games available.');
    return;
  }

  // Create a seed from today's date
  const seed = today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate();
  
  // Use seeded random to get a consistent index for today
  const randomIndex = Math.floor(seededRandom(seed) * validGames.length);
  const selectedGame = validGames[randomIndex];
  
  setCurrentGame(selectedGame);
  initializeGuessedPhrase(selectedGame.solution.text, selectedGame.startingLetters);
};


  const loadGameForToday = () => {
    const today = getDateWithoutTime(new Date());
    const diffInTime = today.getTime() - getDateWithoutTime(START_DATE).getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

    const gameIndex = diffInDays % gameData.length;
    const selectedGame = gameData[gameIndex];
    setCurrentGame(selectedGame);
    initializeGuessedPhrase(selectedGame.solution.text, selectedGame.startingLetters);
  };

  const loadGameById = (gameId) => {
    const selectedGame = gameData.find((game) => game.id === parseInt(gameId));
    if (selectedGame) {
      setCurrentGame(selectedGame);
      initializeGuessedPhrase(selectedGame.solution.text, selectedGame.startingLetters);
    } else {
      alert('Invalid game ID. Loading today\'s game.');
      loadRandomPastGame();
    }
  };

  const initializeGuessedPhrase = (solutionText, startingLetters = []) => {
    const words = solutionText.split(' ');
    const initialGuesses = words.map((word) =>
      word.split('').map((char) => {
        if (!/[a-zA-Z]/.test(char)) {
          return { char, type: 'non-alphabetic' };
        }
        return { char: '_', type: 'alphabetic' };
      })
    );

    const updatedGuessedPhrase = [...initialGuesses];
    let newRevealedLetters = [];

    words.forEach((word, wordIndex) => {
      word.split('').forEach((char, charIndex) => {
        if (startingLetters.includes(char.toLowerCase()) && /[a-zA-Z]/.test(char)) {
          updatedGuessedPhrase[wordIndex][charIndex] = { char: char.toUpperCase(), type: 'alphabetic' };
          newRevealedLetters.push({ wordIndex, charIndex });
        }
      });
    });

    setGuessedPhrase(updatedGuessedPhrase);
    setRevealedLetters(newRevealedLetters);
    setWordRevealUsed(false);
  };

  const handleNewGameClick = () => {
    const today = new Date();
    const validGames = gameData.filter((game) => new Date(game.printDate) < today);

    if (validGames.length > 0) {
      const randomGame = validGames[Math.floor(Math.random() * validGames.length)];
      window.location.search = `?gameId=${randomGame.id}`;
    } else {
      alert('No valid games available at this time.');
    }
  };

  const handleRevealLetter = () => {
    if (!currentGame || revealCount >= 5 || !isFieldSelected || !selectedLetter) return;

    const updatedGuessedPhrase = [...guessedPhrase];
    let newRevealedLetters = [...revealedLetters];

    guessedPhrase.forEach((word, wordIndex) => {
      word.forEach((charObj, charIndex) => {
        if (
          currentGame.solution.text.split(' ')[wordIndex][charIndex].toUpperCase() === selectedLetter.toUpperCase()
        ) {
          updatedGuessedPhrase[wordIndex][charIndex] = {
            char: selectedLetter.toUpperCase(),
            type: 'alphabetic',
          };
          newRevealedLetters.push({ wordIndex, charIndex });
        }
      });
    });

    setGuessedPhrase(updatedGuessedPhrase);
    setRevealedLetters(newRevealedLetters);
    setRevealCount(revealCount + 1);
    setIsFieldSelected(false);
    setSelectedLetter(null);
    checkWinCondition(updatedGuessedPhrase);
  };

  const handleLetterInput = (letter, wordIndex, charIndex) => {
    if (gameWon) return;

    const updatedGuessedPhrase = [...guessedPhrase];
    updatedGuessedPhrase[wordIndex][charIndex] = { char: letter.toUpperCase(), type: 'alphabetic' };
    setGuessedPhrase(updatedGuessedPhrase);

    moveToNextInput(wordIndex, charIndex + 1);

    checkWinCondition(updatedGuessedPhrase);
  };

  const handleKeyDown = (e, wordIndex, charIndex) => {
    if (e.key === 'Backspace' && !gameWon) {
      e.preventDefault();
      if (inputRefs.current[wordIndex][charIndex].value === '') {
        moveToPreviousInput(wordIndex, charIndex - 1);
      } else {
        const updatedGuessedPhrase = [...guessedPhrase];
        updatedGuessedPhrase[wordIndex][charIndex] = { char: '_', type: 'alphabetic' };
        setGuessedPhrase(updatedGuessedPhrase);
      }
    }
  };

  const moveToNextInput = (wordIndex, charIndex) => {
    let nextWordIndex = wordIndex;
    let nextCharIndex = charIndex;

    while (nextWordIndex < inputRefs.current.length) {
      if (nextCharIndex >= inputRefs.current[nextWordIndex].length) {
        nextWordIndex += 1;
        nextCharIndex = 0;
      }

      if (
        nextWordIndex < inputRefs.current.length &&
        inputRefs.current[nextWordIndex][nextCharIndex] &&
        !revealedLetters.some(
          ({ wordIndex: wIdx, charIndex: cIdx }) => wIdx === nextWordIndex && cIdx === nextCharIndex
        )
      ) {
        inputRefs.current[nextWordIndex][nextCharIndex].focus();
        break;
      } else {
        nextCharIndex += 1;
      }
    }
  };

  const moveToPreviousInput = (wordIndex, charIndex) => {
    let prevWordIndex = wordIndex;
    let prevCharIndex = charIndex;

    while (prevWordIndex >= 0) {
      if (prevCharIndex < 0) {
        prevWordIndex -= 1;
        if (prevWordIndex >= 0) {
          prevCharIndex = inputRefs.current[prevWordIndex].length - 1;
        }
      }

      if (
        prevWordIndex >= 0 &&
        inputRefs.current[prevWordIndex][prevCharIndex] &&
        !revealedLetters.some(
          ({ wordIndex: wIdx, charIndex: cIdx }) => wIdx === prevWordIndex && cIdx === prevCharIndex
        )
      ) {
        inputRefs.current[prevWordIndex][prevCharIndex].focus();
        break;
      } else {
        prevCharIndex -= 1;
      }
    }
  };

  const checkWinCondition = (updatedGuessedPhrase) => {
    const flattenedSolution = currentGame.solution.text.replace(/\s+/g, '').toUpperCase();
    const flattenedGuesses = updatedGuessedPhrase
      .flat()
      .map((charObj) => charObj.char)
      .join('')
      .toUpperCase();
    if (flattenedSolution === flattenedGuesses) {
      setGameWon(true);
    }
  };

  const handleFieldSelect = (wordIndex, charIndex) => {
    const selectedLetter = currentGame.solution.text.split(' ')[wordIndex][charIndex];
    setIsFieldSelected(true);
    setSelectedLetter(selectedLetter);
  };

  const allLettersEnteredOrRevealed = () => {
    return guessedPhrase.every((word) => word.every((charObj) => charObj.char !== '_'));
  };

  const handleSubmitPhrase = () => {
    const flattenedSolution = currentGame.solution.text.replace(/\s+/g, '').toUpperCase();
    const flattenedGuesses = guessedPhrase
      .flat()
      .map((charObj) => charObj.char)
      .join('')
      .toUpperCase();

    if (flattenedSolution === flattenedGuesses) {
      setGameWon(true);
    } else {
      alert('Incorrect phrase, try again!');
    }
  };

  const handleShareResults = () => {
    const shareText = `Zorsle.com - #${currentGame.id} “${currentGame.clue}” ${
      rankEmojis[revealCount]
    }`;
    navigator.clipboard.writeText(shareText).then(
      () => {
        setCopySuccess('Results copied to clipboard!');
      },
      (err) => {
        setCopySuccess('Failed to copy!');
      }
    );
  };

  const changeArchive = (selectedDate) => {
    const diffInTime = getDateWithoutTime(selectedDate).getTime() - getDateWithoutTime(START_DATE).getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
    const gameIndex = diffInDays % gameData.length;

    const selectedGame = gameData[gameIndex];
    window.location.search = `?gameId=${selectedGame.id}`;
  };

  if (!currentGame) return <div>Loading...</div>;

  return (
    <div className="puzzle-container">
      <Helmet>
        {gameId && <meta name="robots" content="noindex" />}
      </Helmet>
      <div>
        <p>Select a space to reveal letters or to start typing.</p>
      </div>
      <div className="puzzle-title">{currentGame.clue}</div>
      <div className="phrase-input-container">
        {guessedPhrase.map((word, wordIndex) => (
          <div className="word-container" key={wordIndex}>
            {word.map(({ char, type }, charIndex) =>
              type === 'non-alphabetic' ? (
                <span key={`${wordIndex}-${charIndex}`} className="non-alphabetic-char phrase-input">
                  {char}
                </span>
              ) : (
                <input
                  key={`${wordIndex}-${charIndex}`}
                  type="text"
                  maxLength="1"
                  ref={(el) => {
                    if (!inputRefs.current[wordIndex]) inputRefs.current[wordIndex] = [];
                    inputRefs.current[wordIndex][charIndex] = el;
                  }}
                  value={char === '_' ? '' : char}
                  onChange={(e) => handleLetterInput(e.target.value, wordIndex, charIndex)}
                  onKeyDown={(e) => handleKeyDown(e, wordIndex, charIndex)}
                  className="phrase-input"
                  onClick={() => handleFieldSelect(wordIndex, charIndex)}
                  disabled={
                    revealedLetters.some(
                      ({ wordIndex: wIdx, charIndex: cIdx }) =>
                        wIdx === wordIndex && cIdx === charIndex
                    ) || gameWon
                  }
                />
              )
            )}
          </div>
        ))}
      </div>

      <div className="reveals-used-container">
        <p>Reveals Used:</p>
        {Array.from({ length: 5 }).map((_, i) => (
          <div key={i} className={`reveal-circle ${i < revealCount ? 'filled' : ''}`}></div>
        ))}
      </div>

      <div class="reveal-button-container">
      <button
          onClick={handleRevealWord}
          className="reveal-word-button"
          style={{
            backgroundColor: wordRevealUsed ? '#cccccc' : '#4CAF50',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            cursor: wordRevealUsed ? 'not-allowed' : 'pointer',
            fontWeight: 'bold',
            marginLeft: '10px'
          }}
          disabled={gameWon || revealCount >= 5 || wordRevealUsed}
        >
          HINT: REVEAL WORD
        </button>
      </div>

      <div className="reveal-button-container">
        <button
          onClick={handleRevealLetter}
          className="reveal-button"
          disabled={gameWon || revealCount >= 5 || !isFieldSelected}
        >
          Reveal Letter
        </button>
        
        <button
          onClick={handleSubmitPhrase}
          className="enter-phrase-button"
          disabled={!allLettersEnteredOrRevealed() || gameWon}
        >
          Submit Phrase
        </button>
        <button onClick={handleNewGameClick} className="new-game-button">
          New Game
        </button>
      </div>

      <div className="archive-wrapper">
        <select onChange={(e) => changeArchive(new Date(e.target.value))}>
          <option key="" value="">
            --Select an archive date--
          </option>
          {archiveDates.map((date) => {
            const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            return (
              <option key={formattedDate} value={formattedDate}>
                {formattedDate}
              </option>
            );
          })}
        </select>
      </div>

      {gameWon && (
        <div className="congratulations-message">
          <h2>Congratulations</h2>
          <div className="rank-emoji">{rankEmojis[revealCount]}</div>
          <p>
            You revealed {revealCount} {revealCount === 1 ? 'letter' : 'letters'} and guessed the
            phrase correctly.
          </p>
          <button onClick={handleShareResults} className="share-results-button">
            Share Results
          </button>
          {copySuccess && <p>{copySuccess}</p>}
        </div>
      )}
    </div>
  );
};

export default WordPuzzleGame;
